// export const BASE_URL = window.location.hostname === "localhost" ? "http://localhost:2056" : "https://kiosk-api.acharyatech.com";

// export const BASE_URL = "http://dev.rewasoft.net:2056";
export const BASE_URL = "https://api-kiosk.springmounttech.au";
// export const BASE_URL = "https://kiosk-api.acharyatech.com";

export const RatingType = {
    Poor: "Poor",
    Average: "Average",
    Good: "Good",
    Excellent: "Excellent"
}